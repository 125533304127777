import "./sidebar.css";
import { useNavigate, Link, useLocation } from "react-router-dom";

export default function Sidebar({
  isMinimized,
  activeComponent,
  onSidebarClick,
  showDiv,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const handleLoginClick = (event) => {
    event.preventDefault();

    if (!showDiv) {
      navigate("/");
    } else if (showDiv) {
      navigate("/alogin");
    }
  };
  return (
    <div
      className={`sidebar-container ${isMinimized ? "minimized" : "maximum"}`}
    >
      <div className="logo-container">
        <div className="user-logo">
          <div className="circle-wrapper">JA</div>
        </div>
        {!isMinimized && (
          <div className="user-name">
            <span>Jake</span>
          </div>
        )}
      </div>
      <div className="menu-item-list">
        {!showDiv && (
          <div className="top-side-menu">
            <div
              className={`menu-item ${
                activeComponent === "appointments" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("appointments")}
            >
              <svg
                viewBox="0 0 448 512"
                height="25px"
                width="25px"
                fill="#e8eaed"
              >
                <path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z" />
              </svg>
              {!isMinimized && (
                <li className="menu-item-name">My Appointments</li>
              )}
            </div>
            <div
              className={`menu-item ${
                activeComponent === "credentials" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("credentials")}
            >
              <svg
                viewBox="0 0 384 512"
                height="25px"
                width="25px"
                fill="#e8eaed"
              >
                <path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM80 64l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L80 96c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 352c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32zm0 32l0 64 192 0 0-64L96 256zM240 416l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
              </svg>
              {!isMinimized && (
                <li className="menu-item-name">My Credentials</li>
              )}
            </div>
            <div
              className={`menu-item ${
                activeComponent === "settings" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("settings")}
            >
              <svg
                viewBox="0 0 512 512"
                height="25px"
                width="25px"
                fill="#e8eaed"
              >
                <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
              </svg>
              {!isMinimized && <li className="menu-item-name">Settings</li>}
            </div>
          </div>
        )}
        {showDiv && (
          <div className="top-side-menu">
            <Link
              to="/home/dashboard"
              className={`menu-item  link-no-style ${
                location.pathname === "/home/dashboard" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("dashboard")}
            >
              <svg
                viewBox="0 0 448 512"
                height="25px"
                width="25px"
                fill="#e8eaed"
              >
                <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm64 192c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 192c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-192zM320 288c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32z" />
              </svg>
              {!isMinimized && (
                <span className="menu-item-name">Dashboard</span>
              )}
            </Link>
            <Link
              to="/home/users"
              className={`menu-item link-no-style ${
                location.pathname === "/home/users" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("user")}
            >
              <svg
                viewBox="0 0 448 512"
                height="24px"
                width="24px"
                fill="#e8eaed"
              >
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
              </svg>
              {!isMinimized && <span className="menu-item-name">User</span>}
            </Link>
            <Link
              to="/home/subscribers"
              className={`menu-item link-no-style ${
                location.pathname === "/home/users" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("user")}
            >
              <svg
                viewBox="0 0 448 512"
                height="24px"
                width="24px"
                fill="#e8eaed"
              >
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
              </svg>
              {!isMinimized && (
                <span className="menu-item-name">Subscriber</span>
              )}
            </Link>
            <Link
              to="/home/organization"
              className={`menu-item link-no-style ${
                location.pathname === "/home/organization" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("organization")}
            >
              <svg
                viewBox="0 0 512 512"
                height="22px"
                width="22px"
                fill="#e8eaed"
              >
                <path d="M240.1 4.2c9.8-5.6 21.9-5.6 31.8 0l171.8 98.1L448 104l0 .9 47.9 27.4c12.6 7.2 18.8 22 15.1 36s-16.4 23.8-30.9 23.8L32 192c-14.5 0-27.2-9.8-30.9-23.8s2.5-28.8 15.1-36L64 104.9l0-.9 4.4-1.6L240.1 4.2zM64 224l64 0 0 192 40 0 0-192 64 0 0 192 48 0 0-192 64 0 0 192 40 0 0-192 64 0 0 196.3c.6 .3 1.2 .7 1.8 1.1l48 32c11.7 7.8 17 22.4 12.9 35.9S494.1 512 480 512L32 512c-14.1 0-26.5-9.2-30.6-22.7s1.1-28.1 12.9-35.9l48-32c.6-.4 1.2-.7 1.8-1.1L64 224z" />
              </svg>
              {!isMinimized && (
                <span className="menu-item-name">Organization</span>
              )}
            </Link>
            <Link
              to="/home/trainingLocation"
              className={`menu-item link-no-style ${
                location.pathname === "/home/trainingLocation" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("trainingLocations")}
            >
              <svg
                viewBox="0 0 384 512"
                height="25px"
                width="25px"
                fill="#e8eaed"
              >
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
              {!isMinimized && (
                <span className="menu-item-name">Training Location</span>
              )}
            </Link>
            <Link
              to="/home/trainers"
              className={`menu-item link-no-style ${
                location.pathname === "/home/trainers" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("trainers")}
            >
              <svg
                viewBox="0 0 384 512"
                height="22px"
                width="22px"
                fill="#e8eaed"
              >
                <path d="M192 0c-41.8 0-77.4 26.7-90.5 64L64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-37.5 0C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM128 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 432c0-44.2 35.8-80 80-80l64 0c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16L96 448c-8.8 0-16-7.2-16-16z" />
              </svg>
              {!isMinimized && <span className="menu-item-name">Trainers</span>}
            </Link>
            <Link
              to="/home/sessions"
              className={`menu-item link-no-style ${
                location.pathname === "/home/sessions" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("sessions")}
            >
              <svg
                viewBox="0 0 640 512"
                height="22px"
                width="22px"
                fill="#e8eaed"
              >
                <path d="M160 64c0-35.3 28.7-64 64-64L576 0c35.3 0 64 28.7 64 64l0 288c0 35.3-28.7 64-64 64l-239.2 0c-11.8-25.5-29.9-47.5-52.4-64l99.6 0 0-32c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 32 64 0 0-288L224 64l0 49.1C205.2 102.2 183.3 96 160 96l0-32zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352l53.3 0C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7L26.7 512C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
              </svg>
              {!isMinimized && <span className="menu-item-name">Sessions</span>}
            </Link>
            <Link
              to="/home/courses"
              className={`menu-item link-no-style ${
                location.pathname === "/home/courses" ? "active" : ""
              }`}
              onClick={() => onSidebarClick("courses")}
            >
              <svg
                viewBox="0 0 576 512"
                height="22px"
                width="22px"
                fill="#e8eaed"
              >
                <path d="M0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM128 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l224 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-224 0z" />
              </svg>
              {!isMinimized && <span className="menu-item-name">Courses</span>}
            </Link>
          </div>
        )}
        <div className="bottom-side-menu">
          <div className="menu-item flex-div" onClick={handleLoginClick}>
            <div className="flex-div">
              {!isMinimized && <div className="circle-wrappers">JA</div>}
              {!isMinimized && <div>Logout</div>}
            </div>
            {isMinimized && (
              <svg
                height="25px"
                width="25px"
                fill="#e8eaed"
                viewBox="0 0 512 512"
              >
                <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
